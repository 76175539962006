.SampleTile {
    background-color: white;
    border: solid 1px #ddd;
    height: 340px;
    width: 220px;
    border-radius: 0 0 6px 6px;
    margin: 10px;
}

.ImageOverview {
        position: relative;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

	height: 45%;

	font-size: 24px;
        font-weight: 300;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba(black, 0.3);
}

.References {
    height: 42%;
    padding: 15px 5px;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
 }

.Order {
    font-size: 12px;
    margin-left: 5px;
}

.ClientReference {
    text-align: center !important;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
}

.SampleType {
    margin-left: 5px;
    font-size: 15px;
}
