@import '../../shared.scss';

.Error {
    color: red;
    font-weight: bold;
    opacity: 0.6;
}

.Info {
    color: green;
    font-weight: bold;
    opacity: 0.6;
}

.Warn {
    color: rgb(227, 227, 0);
    font-weight: bold;
    opacity: 0.6;
}
