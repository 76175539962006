.AutoSave {
    font-size: 10px;
    margin-left: 25px;

    .Saving {
	color: #000;
    }

    .Saved {
	color: #aaa;
    }
}
