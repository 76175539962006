@import "../../variables.scss";

.TransfersList {
    padding-top: $secondbar-regular-height;
}

.MoveButton {
    position: fixed;
    bottom: 0;
    background-color: $primary;
    width: 100vw;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    height: 70px;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-bottom: 15px;
	font-size: 12px;

	&:first-of-type {
	    text-transform: uppercase;
	    margin-top: 15px;
	    margin-bottom: 5px;
	    font-size: 16px;
	    font-weight: bold;
	}
    }
}

.SecondBar {
    color: $primary;
    font-size: 14px;
    font-weight: bold;

    span {
	margin: 0 10px;
    }
}

.MeshCode {
    color: #6C6C6C;
    font-weight: bold;
}

.Location {
    margin-block-start: 0;
    margin-block-end: 0;
    color: #909090;
    font-size: 13px;
}
