@import "../../variables.scss";

.SecondBar {
    position: fixed;
    top: $app-toolbar-height;


    background-color: white;

    z-index: 1100; // igual à app bar
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%),
		0 4px 5px 0 rgb(0 0 0 / 14%),
		0 1px 10px 0 rgb(0 0 0 / 12%);
}

.Regular {
    height: $secondbar-regular-height;
}

.Tall {
    height: $secondbar-tall-height;
}
