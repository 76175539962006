.AutoCompletesWrapper {
    padding: 20px 25px 40px 25px;

    h3 {
	color: #B3B3B3;
	font-weight: 500;
	font-size: 22px;
	margin-bottom: 50px;
    }
}

.AutoCompleteMeshesList {

    .Header {
	margin-bottom: 20px;
	color: #7D1C0B;
	font-weight: bold;
	font-size: 18px;
    }

    .Row {
	color: #878787;
	font-size: 16px;
	font-weight: 400;
    }
}
