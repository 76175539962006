.IssuesView {
    background-color: #f0f0f0;
    padding: 50px;
    min-height: 100vh;
}

.FormCard {
    width: 640px;
    max-width: 700px;
    margin-bottom: 25px;

    .Content {
	margin: 15px;
	color: #4a4a4a !important;

	h2 {
	    margin-block-end: 0;
	    font-size: 14px;
	    line-height: 20px;
	    font-weight: 400;
	}

	h3 {
	    margin-block-end: 0;
	}

	p {
	    margin-bottom: 20px;
	    font-size: 12px;
	    margin-block-start: 5px;
	}
    }


}
