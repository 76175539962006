.RenderOption
{
    p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-bottom: 4px;
    }

    .Reference {
	font-size: 12px;
	color: #333333;
	letter-spacing: 0.5px;
    }

    .Brand {
	font-weight: bold;
	color: #000000;
	opacity: 0.6;
    }

    .SampleType {
	margin-left: 10px;
	font-size: 12px;
    }


}
