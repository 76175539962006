.SampleMeshWizard {
    padding: 25px;
}

.SampleMeshWizardPrimary {
    ul {
	line-height: 28px;
    }
}

.ErrorText {
    color: rgba(255, 0, 0, 0.8);
    font-size: 14px;
    letter-spacing: 0.5px;
}

.ErrorIcon {
    color: rgba(255, 0, 0, 0.6);
}

.Order {
    font-size: 12px;
    margin-left: 5px;
}

.CurrentQuantity {
    font-size: 14px;
    margin-left: 5px;
}
