@import "../../variables.scss";

.ConfigurationToolbar {
    background-color: #fff;
    height: $secondbar-regular-height;
    margin-top: $app-toolbar-height;
    position: fixed;
}

.ConfigurationArea {
    position: fixed;
    top: calc($app-toolbar-height + $secondbar-regular-height);
    background-color: #f0f0f0;
    height: 83vh;
}

.ChecklistOption {
    color: #000;
    padding: 10px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
}

.Active {
    color: #8b0000;
    border-bottom: 2px solid #8b0000;
}
