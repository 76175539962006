.Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    color: #4a4a4a;
    overflow-y: scroll;
}

.Display {
    height: 100%;
    padding: 10px 25px 25px 25px;
    color: #4d4d4d;

    .Code {
	color: #8b0000;
	font-size: 18px;
    }

    .Section {
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 22px;
	color: #4a4a4a
    }

    b {
	font-weight: 300
    }
}

.Instructions {
    position: fixed;
    top: 40px;
    left: 20px;
    right: 20px;
    height: 120px;
    background-color: #CCC;
    color: #4a4a4a;
    opacity: 0.8;
    padding: 20px;
    line-height: 1.5;

    .Back {
	margin-left: -20px;
	margin-bottom: 10px;
    }

    p {
	margin-block-start: 0;
	margin-block-end: 0;
    }
}

.Debug {
    position: fixed;
    bottom: 80px;
    left: 5px;
    right: 5px;
    height: 80px;
    background-color: yellow;
    padding: 15px;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
    }
}
