.Pending {
    color: #E2E2E2;
}

.MeshCode {
    color: #6C6C6C;
    font-weight: bold;
}

.Location {
    margin-block-start: 0;
    margin-block-end: 0;
    color: #909090;
    font-size: 13px;
}

.PendingRow {

    .MeshCode {
	color: #CDCDCD;
    }

    .Location {
	color: #CDCDCD;
    }

    .Pending {
	color: #BBBBBB;
    }

}
