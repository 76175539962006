.MeshSampleList {
    margin-top: 20px;

    .MeshSampleItem {
	margin-bottom: 5px;
	padding: 5px 15px;

	.Reference {
	    font-size: 12px;
	    color: #333333;
	    letter-spacing: 0.5px;
	    margin-bottom: 2px;
	}

	.Brand {
	    font-weight: bold;
	    color: #000000;
	    opacity: 0.5;
	}

	.SampleType {
	    margin-left: 10px;
	    font-size: 12px;
	}

	&:hover {
	    background-color: #efefef;
	}

	.Confirm {
	    visibility: hidden;
	}

	&:hover .Confirm {
	    visibility: visible;
	}


    }
}
