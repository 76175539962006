.TopicPreview {
    width: 350px;
    cursor: pointer;
    margin: 15px;

    .TopicIcon {
	font-size: 48px;
	color: #8e454d;
    }

    .TopicName {
	margin: 15px 0 25px 0;
	color: #4a4a4a;
    }

    .TopicPreviewContent {
	height: 100%;
    }

    .LastUpdate {
	align-self: flex-end;
    }
}
