.Meshes {
    background-color: #F0F0F0;
    height: 100vh;
    transition: all 300ms ease !important;
    opacity: 1;

    .FilterBar {
	padding: 0px 20px;
    }

    .Filter {
	border-left: 1px solid #ccc;
	padding: 0px 20px;
	max-width: 220px;
    }

    .Reference {
	color: #8B0B00;
	font-weight: 500;
	opacity: 0.8;
    }

    .MeshesList {
	padding: 100px 20px 0px 20px;
	color: #000000;
	opacity: 0.8;
    }

    .PaginationWrapper {
	margin-top: 25px;
    }

}


.GlobalFabButton {
    position: fixed !important;
    bottom: 25px !important;
    right: 25px !important;
}
