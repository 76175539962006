@import '../../mixins.scss';
@import '../../shared.scss';

.TicketNew {
    color: $ticket-new-color;
    font-size: 12px !important;
}

.TicketProgress {
    color: $ticket-progress-color;
    font-size: 12px !important;
}

.TicketClosed {
    color: $ticket-closed-color;
    font-size: 12px !important;
}

.TicketPending {
    color: $ticket-pending-color;
    font-size: 12px !important;
}

.TicketCanceled {
    color: $ticket-canceled-color;
    font-size: 12px !important;
}

.TicketsGrid {
    margin-top: 50px;
}

.TicketPreview {
    width: 350px;
    cursor: pointer;
    margin: 15px;

    .Wrapper {
	box-sizing: border-box;
	height: 100%;
    }

    .Content {
	height: 100%;
    }

    .TicketName {
	@include TruncateText()
    }

    .TicketDescription {
	margin: 20px 0 20px 0;
    }

    .TicketPrivacy {
	color: rgba(0,0,0,0.54);
	font-size: 14px;
    }

}

.FabButton {
    position: fixed !important;
    bottom: 25px !important;
    right: 25px !important;
}

.TicketsEmptyState {
    margin-top: 60px;
    font-size: 24px;
    color: #999;
}

.FilterBar {
    margin-bottom: 30px;

    & > div {
	padding-left: 20px;
    }
}
