.Empty {

    margin-bottom: 25px;

    .Wrapper {
	opacity: 0.5;

	p {
	    padding: 10px;
	    font-size: 16px;
	    color: RGBA(0,0,0, 0.5);
	    text-align: center;
	    font-weight: bold;
	    letter-spacing: 0.5px;
	}
    }

}

.EmptyView {
    h1 {
	color: rgba(0, 0, 0, 0.87);
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 32px;
	font-weight: 300;
	opacity: 0.5;
	text-align: center;
	top: 250px;
	position: absolute;
    }
}
