.SupplierAliases {
    margin-top: 20px;
    padding-left: 25px;
    max-width: 300px;
    color: #878787;

    h3 {
	color: #B3B3B3;
    }


}
