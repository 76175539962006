.ConfigurationSelectList {
    margin: 25px;
}

.ListLimit {
    max-height: 85vh;
    overflow-y: auto;
}

.SelectOption {
    cursor: pointer;

    &:hover {
	background-color: #ececec;
    }
}
