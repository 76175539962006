.Dropzone {
    background-color: #fff;
    height: 50px;
    border: 2px dashed #d7d7d7;
    padding: 50px;
    color: #4a4a4a;
    margin-top: 25px;
    cursor: pointer;

    p {
	text-align: center;
    }
}
