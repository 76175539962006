.MeshLocationList {
    margin-top: 10px;

    .MeshLocationItem {
	margin-bottom: 5px;
	padding: 0 15px;

	.Reference {
	    font-size: 16px;
	    color: #333333;
	    letter-spacing: 0.5px;
	    margin-bottom: 2px;
	}

	&:hover {
	    background-color: #efefef;
	}

	.Confirm {
	    visibility: hidden;
	}

	&:hover .Confirm {
	    visibility: visible;
	}


    }
}
