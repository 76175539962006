.FilterBar {
    padding: 0px 20px;
}

.Filter {
    border-left: 1px solid #ccc;
    padding: 0px 20px;
    max-width: 270px;
}


.Samples {
    padding: 100px 20px 0px 20px;



}
