.Wrapper {
    margin-top: 24px;
    margin-bottom: 54px;
    padding-left: 35px;
    padding-right: 30px;
}

.Name {
    font-weight: bold;
    font-size: 28px;
    color: #4d4d4d;
    margin-block-start: 0;
    margin-block-end: 0;
}

.AuthorDate {
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 8px;
}


.Description {
    padding-top: 16px;
    font-size: 18px;
    font-weight: 300;
    color: #4d4d4d;
    line-height: 1.6em;
}


.TicketState {
    color: #8b0000;
    text-transform: uppercase;
    font-weight: 400;
}

.SectionHeader {
    font-size: 20px;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 28px;
}

.TicketPreviewHorizontal {
    background-color: #a8a8a8;
    cursor: pointer;
    margin-right: 10px;
    padding: 2px 5px;
    margin-top: 20px;
    font-weight: 400;
    color: #fff;
    margin-bottom: -10px;
    font-size: 12px;
    letter-spacing: 1px;
}

.TicketPreview {
    width: 80px;
    height: 80px;
    background-color: #f2f2f2;
    cursor: pointer;

    svg {
	fill: #a8a8a8;
	margin-bottom: 0;
	font-size: 28px;
    }

    p {
	margin-block-start: 0;
	margin-block-end: 0;
	font-weight: bold;
	color: #808080;
	font-size: 16px;
	margin-top: -5px;
    }

}

.TicketComment {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
    }

    .Author {
	padding-top: 12px;
	font-weight: bold;
    }

    .Spacer {
	font-weight: 300;
	padding: 0 4px;
    }

    .Date {
	font-weight: 300;
	font-size: 14px;
    }

    .Content {
	font-size: 16px;
	font-weight: 300;
	color: #4d4d4d;
	line-height: 1.6em;
	margin: 16px 0;

	a {
	    color: #8b0000;
	    text-decoration: none;
	}

	a:hover {
	    text-decoration: underline;
	}
    }

    &:last-of-type {
	border-bottom: 1px solid transparent;
    }
}

.NewFilePreview {
    width: 100%;
    background-color: #F2F2F2;
    padding: 5px;
    margin: 5px 0;
    font-size: 12px;
    color: #808080;

    &:first-of-type {
	margin-top: 20px;
    }
}
