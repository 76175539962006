@import "../../variables.scss";

.AppToolbar{
    a {
	color: #fff;
    }
    height: $app-toolbar-height;
}

.Logo {
    height: 52px;
}
