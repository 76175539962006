.SampleDetailsView {
    padding-top: 200px;
    height: 100%;

    .Quantity {
	font-weight: bold;
	opacity: 0.8;
	margin-right: 5px;
    }
}
