.SupplierConfiguration {
    margin: 25px;
    border: 1px solid #8B0000;

    .CardHeader {
	background-color: #8B0000;
	height: 50px;
	padding: 0 10px;

	p {
	    color: #fff;
	    font-size: 20px;
	}

	.Close {
	    color: #fff;
	}
    }
}

.Idle {
    margin: 25px 0 0 0;

    p:first-of-type {
	font-size: 2em;
	font-weight: 300;
	margin-block-start: 0;
	margin-block-end: 0;
	color: #4a4a4a;
    }

    p {
	font-weight: bold;
	color: #4a4a4a;
    }
}
