
.SideMenuHeader {
    background-color: rgb(139,0,0);
    color: white !important;
    padding: 20px;

    img {
	max-height: 50px;
	margin-left: 40px;
    }

    svg {
	color: white;
    }
}

.Sidemenu {
    width: 225px;
    height: 100%;
    background-color: #fafafa;
    padding: 30px 20px;

    .Buttons {

	> a {
	    margin: 0 0 15px 15px;
	    text-decoration: none;
	    color: #212121;
	    font-weight: 500;
	    font-size: 14px;
	    opacity: 0.8;
	    vertical-align: middle;

	    &:hover {
		opacity: 1;
	    }

	    svg {
		margin-right: 10px;
		color: #737373;
	    }
	}

	.Disabled {
	    opacity: 0.5;

	    &:hover {
		opacity: 0.5;
	    }
	}
    }
}
