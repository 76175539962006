.MeshViewToolbar {
    padding: 0 20px;

    .Actions {
	> button {
	    margin-right: 10px;
	}
    }
}

.MeshView {
    padding-top: 100px;
    background-color: #F0F0F0;
    padding-bottom: 100px;

    .MeshCard {
	min-width: 1400px;


	.CenterCol {
	    border-right: 1px solid #eee;
	    border-left: 1px solid #eee;
	}
    }
}

.Section {
    padding: 25px;

    .SectionTitleWrapper {
	margin-top: 25px;
	margin-bottom: 35px;

	.SectionTitle {
	    margin-top: 25px;
	    font-size: 22px;
	    font-family: Roboto;
	    color: RGBA(0, 0, 0, 0.87);
	    opacity: 0.5;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    font-weight: 400;
	}

	.SectionNote {
	    font-size: 10px;
	    opacity: 0.7;
	    margin-block-start: 0;
	    margin-left: 10px;
	}

    }

    h3 {
	margin-top: 25px;
	font-size: 22px;
	font-family: Roboto;
	color: RGBA(0, 0, 0, 0.87);
	opacity: 0.5;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
    }

    h5 {
	margin-top: 35px;
	font-size: 14px;
	font-family: Roboto;
	opacity: 0.8;
	letter-spacing: 0.5px;
	font-weight: 500;
    }
}

.FormLine {
    margin-top: 25px;
    margin-bottom: 25px;
}
